@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

#randomize-me-view {
    .header {
        font-family: 'Poppins', sans-serif;
        min-height: 100vh;
        background-image: url(/assets/randomize-me/background.jpg);
        background-size: cover;
        background-position: center;
        display: flex;
        color: white;
        flex-direction: column;
        padding: 40px;
        align-items: center;
        overflow-y: hidden;

        h1 {
            font-weight: 700;
            font-size: 5em;
            text-align: center;
            margin-bottom: 20px;
            margin-top: -10px;
        }

        .brand {
            display: flex;
            align-items: center;

            h2 {
                font-weight: 700;
                font-size: 2em;
            }

            img {
                width: 50px;
                display: block;
                margin-right: 10px;
            }
        }

        .badges {
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            img {
                height: 60px;
                align-self: center;
                margin: 0 10px;
            }
        }

        img.preview {
            display: block;
            width: 500px;
        }
    }

    @media screen and (max-width: 1100px) {
        
    }

    @media screen and (max-width: 768px) {
        .header {
            .brand {
                h2 {
                    font-size: 1.5em;
                }

                img {
                    width: 30px;
                }
            }

            h1 {
                font-size: 3em;
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: 540px) {
        .header {
            padding: 40px 20px;

            h1 {
                font-size: 3em;
            }

            .badges {
                flex-direction: row;

                img {
                    width: 150px;
                    height: auto;
                }
            }

            img.preview {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 350px) {
        .header {
            h1 {
                font-size: 2.5em;
            }

            .badges {
                img {
                    width: 120px;
                }
            }
        }
    }
}