@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);

*{margin:0;padding:0;font-weight:300;box-sizing:border-box}html,body{font-family:'Roboto', sans-serif}.section{padding:20px}.section:last-child{padding-top:0}.section h1{margin-bottom:10px}

#header{height:600px;display:flex;align-items:center;justify-content:center;background-image:url(/assets/header.jpg);background-position:center;background-size:cover;color:white}#header .header-content{padding:20px;display:flex;flex-direction:column;align-items:center}#header .header-content #hello-internet{color:#E5DB73;font-size:3em;font-weight:100;background-color:rgba(255,255,255,0.1);padding:10px;border-radius:5px;text-align:center}#header .header-content #hello-internet::before{content:'(';margin-right:10px;color:white}#header .header-content #hello-internet::after{content:')';margin-left:10px;color:white}#header .header-content .coder{margin:30px 0;display:flex;align-items:center;justify-content:center}#header .header-content .coder>div{width:40px;height:40px;background-image:url(/assets/apple-man-emoji.png);background-repeat:no-repeat;background-position:center;background-size:60%;background-color:white;border-radius:50%;padding:10px}#header .header-content .description{text-align:center;font-size:2em;font-weight:100}

#projects a{color:black}#projects .projects-list{display:flex;margin:0 -10px;flex-wrap:wrap}#projects .projects-list .project-box{flex:0 0 25%;display:flex;padding:10px}@media screen and (max-width: 1200px){#projects .projects-list .project-box{flex:0 0 33.3333%}}@media screen and (max-width: 998px){#projects .projects-list .project-box{flex:0 0 50%}}@media screen and (max-width: 600px){#projects .projects-list .project-box{flex:0 0 100%}}#projects .projects-list .project-box .project{flex:1 1;display:flex;flex-direction:column}#projects .projects-list .project-box .project .project-header{height:200px;background-size:50px 50px;background-repeat:no-repeat;background-position:center;border-top-left-radius:5px;border-top-right-radius:5px}#projects .projects-list .project-box .project .project-body{flex:1 1;padding:10px;border-bottom-left-radius:5px;border-bottom-right-radius:5px;border-width:1px;border-style:solid;border-top:none}#projects .projects-list .project-box .project .project-body .project-title{font-size:1.2em}#projects .projects-list .project-box .project .project-body .project-type{font-size:0.9em;color:#9b9b9b;margin-top:5px}#projects .projects-list .project-box .project .project-body .project-description{margin-top:10px;line-height:150%}

#footer{padding:20px;background-color:black;color:white}#footer .footer-columns{display:flex;flex-wrap:wrap}#footer .footer-columns .footer-col{flex:1 1;padding:10px}#footer .footer-columns .footer-col h2{margin-bottom:20px}#footer .footer-columns .footer-col p{line-height:150%}@media screen and (max-width: 1000px){#footer .footer-columns .footer-col{flex:0 0 100%;margin-bottom:20px}#footer .footer-columns .footer-col:last-child{margin-bottom:0}}#footer .contact-list .contact-item{margin-bottom:15px}#footer .contact-list .contact-item a{display:inline-flex;align-items:center;color:white;text-decoration:none}#footer .contact-list .contact-item a .contact-icon{width:30px;height:30px;background-size:contain;background-repeat:no-repeat;background-position:center;margin-right:5px}#footer .contact-list .contact-item a:hover{text-decoration:underline}#footer .contact-list .contact-item:last-child{margin-bottom:0px}#footer .site-map .site-map-item{margin-bottom:10px}#footer .site-map .site-map-item a{color:white;text-decoration:none}#footer .site-map .site-map-item a:hover{text-decoration:underline}#footer .site-map .site-map-item::before{content:'>';margin-right:10px}#footer .site-map .site-map-item::last-child{margin-bottom:0}#footer .site-map .site-map-item.subitem{margin-left:20px}#footer .site-map .site-map-item.subitem::before{content:'#'}#footer .no-bytes{text-align:center;margin:20px 0 30px;font-size:1.1em;color:#595959;font-weight:400;padding:0 20px;line-height:150%}@media screen and (max-width: 768px){#footer .no-bytes{font-size:0.9em}}

#technologies .technologies-list{display:flex;margin:0 -10px;flex-wrap:wrap}#technologies .technologies-list .technology-box{flex:0 0 20%;padding:10px}#technologies .technologies-list .technology-box .technology{padding:15px;background-color:#75715E;color:white;display:flex;align-items:center;border-radius:5px}#technologies .technologies-list .technology-box .technology .icon{width:35px;height:35px;background-size:contain;background-position:center;background-repeat:no-repeat;margin-right:10px;-webkit-filter:brightness(0) invert(100%);filter:brightness(0) invert(100%)}@media screen and (max-width: 1250px){#technologies .technologies-list .technology-box{flex:0 0 25%}}@media screen and (max-width: 998px){#technologies .technologies-list .technology-box{flex:0 0 33.3333%}}@media screen and (max-width: 850px){#technologies .technologies-list .technology-box{flex:0 0 50%}}@media screen and (max-width: 768px){#technologies .technologies-list .technology-box{flex:0 0 100%}}

#about-me .profile{display:flex;flex-direction:column;align-items:center}#about-me .profile .profile-img{width:200px;height:200px;border-radius:50%;background-color:grey;background-image:url(/assets/profile.jpg);background-size:cover;background-position:center;box-shadow:inset 0 0 10px black}#about-me .profile .profile-info{margin:10px 0 20px 0;font-size:1.2em;text-align:center;line-height:150%}#about-me .columns{display:flex;margin:0 -10px;flex-wrap:wrap}#about-me .columns .column{flex:1 1;padding:10px}#about-me .columns .column h2{border-bottom:1px solid silver;color:silver;padding-bottom:5px}#about-me .columns .column p{margin-top:10px;font-size:1.2em;line-height:150%}@media screen and (max-width: 900px){#about-me .columns .column{flex:0 0 100%}}

#areas-interest .areas-interest-list{display:flex;flex-wrap:wrap;margin:0 -10px}#areas-interest .areas-interest-list .area-interest-box{flex:1 1;padding:10px;display:flex}#areas-interest .areas-interest-list .area-interest-box .area-interest{flex:1 1;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:1.2em;text-align:center;padding:20px;border-radius:5px;box-shadow:inset 0 0 4px black}#areas-interest .areas-interest-list .area-interest-box .area-interest .area-icon{width:70px;height:70px;background-size:contain;background-position:center;background-repeat:no-repeat;margin-bottom:10px}@media screen and (max-width: 1100px){#areas-interest .areas-interest-list .area-interest-box{flex:0 0 50%}}@media screen and (max-width: 767px){#areas-interest .areas-interest-list .area-interest-box{flex:0 0 100%}#areas-interest .areas-interest-list .area-interest-box .area-interest{flex-direction:row;justify-content:flex-start;text-align:left}#areas-interest .areas-interest-list .area-interest-box .area-interest .area-icon{margin-right:15px;margin-bottom:0;width:50px;height:50px}}

*{margin:0;padding:0;font-weight:300;box-sizing:border-box}#randomize-me-privacy-policy-view .content{width:80%;margin:30px auto}#randomize-me-privacy-policy-view .content h1{margin-bottom:30px}#randomize-me-privacy-policy-view .content h2{margin-bottom:10px}#randomize-me-privacy-policy-view .content p{line-height:150%;margin-bottom:10px}#randomize-me-privacy-policy-view .content>div{margin-bottom:20px}#randomize-me-privacy-policy-view .content>div:last-child{margin-bottom:0}@media screen and (max-width: 768px){#randomize-me-privacy-policy-view .content{width:100%;padding:30px 20px;margin:0}}


#randomize-me-view .header{font-family:'Poppins', sans-serif;min-height:100vh;background-image:url(/assets/randomize-me/background.jpg);background-size:cover;background-position:center;display:flex;color:white;flex-direction:column;padding:40px;align-items:center;overflow-y:hidden}#randomize-me-view .header h1{font-weight:700;font-size:5em;text-align:center;margin-bottom:20px;margin-top:-10px}#randomize-me-view .header .brand{display:flex;align-items:center}#randomize-me-view .header .brand h2{font-weight:700;font-size:2em}#randomize-me-view .header .brand img{width:50px;display:block;margin-right:10px}#randomize-me-view .header .badges{display:flex;align-items:center;margin-bottom:40px}#randomize-me-view .header .badges img{height:60px;align-self:center;margin:0 10px}#randomize-me-view .header img.preview{display:block;width:500px}@media screen and (max-width: 768px){#randomize-me-view .header .brand h2{font-size:1.5em}#randomize-me-view .header .brand img{width:30px}#randomize-me-view .header h1{font-size:3em;margin-top:0}}@media screen and (max-width: 540px){#randomize-me-view .header{padding:40px 20px}#randomize-me-view .header h1{font-size:3em}#randomize-me-view .header .badges{flex-direction:row}#randomize-me-view .header .badges img{width:150px;height:auto}#randomize-me-view .header img.preview{width:100%}}@media screen and (max-width: 350px){#randomize-me-view .header h1{font-size:2.5em}#randomize-me-view .header .badges img{width:120px}}

